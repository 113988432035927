<script setup lang="ts">
import { Coords } from '~/lib/models/geolocation'
import { BaseRestaurantOrder } from '~/lib/services/store/order/order.dto'

defineProps<{
  restaurant: BaseRestaurantOrder
  coords: Coords
}>()

const { t } = useLocale()
</script>

<template>
  <section class="flex flex-row items-start gap-2 rounded-full bg-mcd-secondaryIvory p-1 px-4">
    <img src="/images/icons/pickUp.svg" class="h-7 w-auto" alt="pickup marker" />
    <div>
      <p class="text-xs font-bold">
        {{ t('restaurant.detail.header.MOP') }}
      </p>
      <p class="line-clamp-2 text-xs text-mcd-secondaryDarkGrey">
        <span v-if="restaurant.name" class="font-bold">{{ restaurant.name }}</span>
        &nbsp;
        <span v-if="restaurant.address">{{ restaurant.address }}</span>
      </p>
    </div>
  </section>
</template>
